<template>
  <div class="abitur-table">
    <ui-loading v-if="data.length == 0 && !simulation" label="Cargando Reporte"></ui-loading>
    <div class="table1_container" v-if="data.length > 0">
      <!-- v-if="firstTable != null" -->
      <div v-if="simulator" class="simulator_buttons">
        <input type="button" :value="$t('Abitur.fillwidthmean')" @click="firstAverage()"/>
        <input type="button" :value="$t('Abitur.clear')" @click="clearSimulation()"/>
        <input type="button" :value="$t('Abitur.save')" @click="saveSimulation()"/>
      </div>
      <table width="100%" cellspacing="0" cellpadding="0" class="abiturmatrix1">
        <thead>
          <tr>
            <td rowspan="2"></td>
            <td rowspan="2" colspan="2">
              Fach/Unterrichtssprache
              <br />Subject/Language of instruction
            </td>
            <td colspan="4">
              Punktzahl der Halbjahresleistungen in einfacher Wertung in der
              <br />Qualifikationsphase / Single value credit points in each semester
            </td>
          </tr>
          <tr>
            <td>
              1. Halbjahr
              <br />1st Semester
            </td>
            <td>
              2. Halbjahr
              <br />2nd Semester
            </td>
            <td>
              3. Halbjahr
              <br />3rd Semester
            </td>
            <td>
              4. Halbjahr
              <br />4th Semester
            </td>
          </tr>
        </thead>
        <template v-for="(area, pos) in firstTable">
          <template v-for="(record, i) in area">
            <tr :key="pos+'_'+i">
              <td v-if="i === 0" :rowspan=area.length > 
                <div v-if="pos == 'lang'">
                  Sprachlich-<br/>
                  literarisch-<br/>
                  künstlerisches Aufgabenfeld
                </div>
                <div v-if="pos == 'social'">
                  Gesellschaftswissen-<br/>
                  schaftliches Aufgabenfeld
                </div>
                <div v-if="pos == 'math'">
                  Mathematisch-<br/>
                  naturwissenschaftlich-<br/>
                  technisches<br/>
                  Aufgabenfeld
                </div>
                
              </td>
              <template v-for="(grade, k) in record">
                <td :key="k" v-if="k < 1">{{ grade.subject_name }}</td>
                <td :key="k+1" v-if="k < 1" style="text-align:center">
                  <span v-if="pinsc.hasOwnProperty(grade.course_code.replace('11', '12'))">
                    {{ pinsc[grade.course_code.replace('11', '12')] }}
                  </span>
                </td>
                <template v-if="record.length == 1 && grade.course_code.includes('12')">
                  <td :key="grade.id+k+1">?</td>
                  <td :key="grade.id+k+2">?</td>
                </template>
                <template v-if="grade.course_code.includes('12') && simulator">
                  <td :class="['input', 'matrixcell', {activeFilter: grade.filter > 0}, {clickable: clickable}, {lost:grade.ordinary < min_passgrade && grade.ordinary >= 0}]" 
                    :key="grade.course_code+k+1"
                    @click="data[grade.arrayIndex].ordinary && clickable? 
                      toggle(data[grade.arrayIndex], 'filter', grade.arrayIndex)
                      : null">
                      <input type="text" v-model="data[grade.arrayIndex].ordinary" @click.stop>
                  </td>
                  <td :class="['input', 'matrixcell', {activeFilter: grade.filter_extra > 0}, {clickable: clickable}, {lost:grade.extraordinary < min_passgrade && grade.extraordinary >= 0}]"  
                    :key="grade.course_code+k+2"
                    @click="data[grade.arrayIndex].extraordinary && clickable?
                     toggle(data[grade.arrayIndex], 'filter_extra', grade.arrayIndex)
                     : null">
                      <input type="text" v-model="data[grade.arrayIndex].extraordinary" @click.stop>
                  </td>
                </template>
                <td
                  v-if="grade.course_code.includes('11') || !simulator"
                  :key="grade.id"
                  @click="grade.ordinary && clickable? toggle(grade, 'filter', grade.arrayIndex): null"
                  :class="['matrixcell', {activeFilter: grade.ordinaryMark}, {clickable: clickable}, {lost:grade.ordinary < min_passgrade && grade.ordinary >= 0}]"
                ><span v-if="grade.ordinary">{{ grade.ordinary }}</span><span v-else>{{'\xa0'}}</span></td>
                <td
                  v-if="grade.course_code.includes('11') || !simulator"
                  :key="grade.id+k"
                  @click="grade.extraordinary && clickable? toggle(grade, 'filter_extra', grade.arrayIndex): null"
                  :class="['matrixcell', {activeFilter: grade.extraordinaryMark}, {clickable: clickable}, {lost:grade.extraordinary < min_passgrade && grade.extraordinary >= 0}]"
                ><span v-if="grade.extraordinary">{{ grade.extraordinary }}</span><span v-else>{{'\xa0'}}</span></td>
                <template v-if="record.length == 1 && grade.course_code.includes('11')">
                  <td :key="grade.id+k+1"></td>
                  <td :key="grade.id+k+2"></td>
                </template>
              </template>
            </tr>
          </template>   
        </template>
      </table>
    </div>

    <!-- <p>MARKS : {{ marksAvailable }}</p> -->
    <br />
    <br />
    <br />
    <div class="halbja" v-if="data.length > 0">
      <div class="data">
        <table width="100%" cellspacing="0" cellpadding="10" class="abiturhalbja">
          <tr>
            <td :class="['left', {error: belegt < 40}]"> belegt: </td>
            <td :class="['enclosed',{error: belegt < 40}]"> {{ belegt }}</td>
            <td class="right" :class="{error: belegt < 40}"> <span v-if="belegt >= 40">>=</span><span v-else> < </span> 40 </td>
          </tr>
          <tr>
            <td :class="['left', {error:(belegt - marksAvailable) != 36}]">eingebracht: </td>
            <td :class="['enclosed',{error:(belegt - marksAvailable) != 36}]">{{ belegt - marksAvailable }}</td>
            <td class="right" :class="{error:(belegt - marksAvailable) != 36}"> <span v-if="(belegt - marksAvailable) == 36"> = </span><span v-else>!=</span> 36 </td>
          </tr>
          <tr>
            <td :class="['left', {error:punkte < 29}]">über 4 Punkte:</td>
            <td :class="['enclosed',{error:punkte < 29}]">{{ punkte }}</td>
            <td class="right" :class="{error:punkte < 29}"> <span v-if="punkte >= 29">>=</span><span v-else> < </span> 29 </td>
          </tr>
          <tr>
            <td class="empty"></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td :class="['left', {error:thisIsQ < 200}]">Punkte gesamt: </td>
            <td :class="['enclosed',{error:thisIsQ < 200}]"> {{ thisIsQ }}</td>
            <td class="right" :class="{error:thisIsQ < 200}"> <span v-if="thisIsQ >= 200">>=</span><span v-else> < </span> 200</td>
          </tr>
        </table>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="table2_container">
      <div v-if="secondTable.length > 0 ">
        <table width="90%" cellspacing="0" cellpadding="0" class="abiturmatrix2">
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td>schriftlich</td>
              <td>mündlich</td>
              <td v-if="simulator">Teilpunktzahl A</td>
            </tr>
          </thead>
          <template v-for="(record, i) in secondTable">
            <tr :key="i">
              <td>P{{ record.iorder - 35 }}</td>
              <td>{{ record.subject_name }}</td>
              <template v-if="!simulator">
                <td class="centered">{{ record.extra3 }}</td>
                <td class="centered">{{ record.extra4 }}</td>
              </template>
              <template v-else>
                <td class="centered input"><input type="text" v-model="data[record.arrayIndex].extra_3" pattern="\d{2}$"></td>
                <td class="centered input"><input type="text" v-model="data[record.arrayIndex].extra_4" pattern="\d{2}$"></td>
              </template>
              <td v-if="simulator" :class="['centered', 'lost',
                  {won:((parseInt(data[record.arrayIndex].extra_3) >= 0 && isNaN(parseInt(data[record.arrayIndex].extra_4))) || (isNaN(parseInt(data[record.arrayIndex].extra_3)) && parseInt(data[record.arrayIndex].extra_4) >= 0)) && teilpunktzahlA(data[record.arrayIndex].extra_3, data[record.arrayIndex].extra_4) >= 20},
                  {won:(parseInt(data[record.arrayIndex].extra_3) >= 0 && parseInt(data[record.arrayIndex].extra_4) >= 0) && teilpunktzahlA(data[record.arrayIndex].extra_3, data[record.arrayIndex].extra_4) >= 20}]">
                <b>{{ teilpunktzahlA(data[record.arrayIndex].extra_3, data[record.arrayIndex].extra_4) }}</b>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <div v-else>No information for this table</div>
      <div v-if="secondTable.length > 0 ">
        <table width="90%" cellspacing="0" cellpadding="0" class="abiturmatrix2">
          <tr>
            <td colspan="2" class="bless">
              <br />
            </td>
          </tr>
          <tr>
            <td>Q</td>
            <td class="centered">{{ thisIsQ }}</td>
          </tr>
          <tr>
            <td>A</td>
            <td class="centered">{{ thisIsA }}</td>
          </tr>
          <tr>
            <td>Q + A</td>
            <td class="centered">{{ thisIsA + thisIsQ }}</td>
          </tr>
          <tr>
            <td>Durchschnitt</td>
            <td class="big centered">{{ abiturGrade["name"] }}</td>
          </tr>
        </table>
      </div>
      <div v-else>Missing information for totals table</div>
    </div>
  </div>
</template>



<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from "@/modules/api/mixins/useApi";
import abiturApi from "@/modules/v3/api/abitur/anexo7.js";
import { UiIcon, UiLoading } from "@/modules/ui/components";

export default {
  name: "abitur-table",
  mixins: [useApi, useI18n],
  api: abiturApi,
  components: { UiLoading },
  props: {
    act_id: {
      //type: Number,
      required: false,
      default: 11
    },
    student_id: {
      // type: Number,
      required: false,
      default: 1045 //1409
    },
    year_id: {
      // type: Number,
      required: false,
      default: 15 //14
    },
    filter_id: {
      // type: Number,
      required: false,
      default: 1
    },
    max_filtered: {
      type: Number,
      required: false,
      default: 14
    },
    scheme_id: {
      type: Number,
      required: false,
      default: 174
    },
    clickable: {
      type: Boolean,
      required: false,
      default: true
    },
    simulator: {
      type: Boolean,
      required: false,
      default: false
    },
    sim_slot: {
      type: Number,
      required: false,
      default: null
    },
    min_passgrade: {
      type: Number,
      required: false,
      default: 5
    },

  },

  data() {
    return {
      data: [],
      pinsc: [],
      abiturGrade: 0,
      simulation: false,
    };
  },

  created() {
    // if (this.simulator){
    //   this.clickable = false;
    // }
    this.fetchData();
    this.getGrade();
  },

  // mounted() {
  //   console.log('AQUI ESTOY! ', this.clickable);
  // },

  computed: {
    value() {
      return {
        act: this.act_id,
        student: this.student_id,
        year: this.year_id,
        simulation: this.simulator ? 1 : 0
      };
    },

    firstTable() {
      let x = this.data.reduce(function(group, x, index) {
        let iorder = parseInt(x.iorder);        
        if ( iorder < 36) {
          (group[iorder] = group[iorder] || []).push({
            arrayIndex: index,
            id: x.id,
            iorder: x.iorder,
            ordinary: x.ordinary,
            extraordinary: x.extraordinary,
            group_id: x.group_id,
            course_code: x.course_code,
            ordinary_period: x.ordinary_period,
            extraordinary_period: x.extraordinary_period,
            inscription_selective: x.inscription_selective,
            subject_name: x.subject_name,
            student: x.student,
            ordinaryMark: x.filter > 0 ? true : false,
            extraordinaryMark: x.filter_extra > 0 ? true : false,
            filter: x.filter,
            filter_extra: x.filter_extra
          });
        }
        // console.log("reduced element", group);
        return group;
      }, {});

      // let grouping = x.reduce(function(areas, x, index) {
      //   (areas['iorder'][index] = areas['iorder'][index] || []).push(x);
      // }, {});
      let grouped = {
        'lang': [],
        'social': [],
        'math': [],
        'sport': [],
      };
       // format numbers 

      // console.log("this is x", x);

      for (const[index, obj] of Object.entries(x)) {
        for(const[inx, item] in obj) {       
          if(obj[inx]?.ordinary){
            obj[inx].ordinary = Math.round(obj[inx].ordinary).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
          }
          if(obj[inx]?.extraordinary){
            obj[inx].extraordinary = Math.round(obj[inx].extraordinary).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
          }
        }

        if (index < 12) {
          grouped.lang.push(obj);
        }else if (index < 21) {
           grouped.social.push(obj);
        }else if (index < 31) {
           grouped.math.push(obj);
        }else {
           grouped.sport.push(obj);
        }
      }
      // console.log('everything I ever ', x);
      // console.log('everything I ever wanted ', grouped);

      // console.log("grupo?", grouped);

      return grouped;
    },


    simulatorDataMatches() {
      let matches = this.data.reduce(function(group, x) {
        let iorder = parseInt(x.iorder);        
        if ( iorder < 36) {
          let code = x["course_code"].replace('11', '');
          code = code.replace('12', '');
          if (!group.hasOwnProperty(code)) {
            group[code] = [];
          }
          group[code].push(x);
        }
        // console.log("reduced element", group);
        return group;
      }, []);
      let keys = Object.keys(matches);

      // this asumes that every code has at least 1 element and that there IS DATA for each... 
      // which should be the one corresponding to 11th grade. .

      let plain = keys.reduce((group, x) => {
        if (matches[x].length < 2) {
          let clone = {...matches[x][0]};
          clone.course_code = `${x}12`;
          clone.ordinary = null;
          clone.extraordinary = null;
          clone.extra3 = null;
          clone.extra4 = null;
          clone.year["id"] = this.year_id;
          clone.year["name"] = null;
          clone.year["start_date"] = null;
          clone.year["end_date"] = null;
          clone.group_id = clone.group_id+'SIM';
          clone.ordinary_period = clone.ordinary_period+"SIM";
          clone.extraordinary_period = clone.extraordinary_period+"SIM";
          clone.extra3_period = clone.extra3_period+"SIM";
          clone.extra4_period = clone.extra4_period+"SIM";
          matches[x].push(clone);
          // console.log('ENTRO UNO');
        }
        // console.log("this is element", x);
        group.push(...matches[x]);  
        return group;
      }, []);

      // console.log(plain)
      return plain;
    },

    secondTable() {
      let y = [];
      // console.log('este es data', this.data);
      y = this.data.reduce((group, x, index) => {
        // console.log('el elemento es ', x);
        if (
          parseInt(x["iorder"]) > 35 &&
          parseInt(x["iorder"]) < 41 && (parseInt(x["extra_3"]) > 0 || parseInt(x["extra_4"]) > 0 || this.simulator)
        ) {
          // console.log('ENTRO!!!');
          group.push({
            arrayIndex: index,
            id: x.id,
            iorder: x.iorder,
            extra3: x.extra_3,
            extra4: x.extra_4,
            group_id: x.group_id,
            course_code: x.course_code,
            subject_name: x.subject_name,
            student: x.student,
            year: x.year_id,
            filter_extra3: x.filter_extra3,
            filter_extra4: x.filter_extra4
          });
        }
        // console.log("reduced element", group);
        return group;
      }, []);
      // console.log('life is one big road with a lot of signs ', y);
      
      return y;
    },

    secondTableNames() {
      let names = this.secondTable.reduce(function(names_group, x, index){
        names_group.push(x.course_code);
        return names_group
      },[]);
      // console.log('this is stnames', names);
      return names;
    },

    thisIsQ() {
      let mQ = this.data.reduce((q, current) => {
        if (parseInt(current["iorder"]) < 35) {
          if (!isNaN(parseInt(current.ordinary)) && parseInt(current.filter) != this.filter_id) {
            q = q + parseInt( Math.round(current.ordinary) );
            //q = q + parseInt(current.ordinary);
            // console.log("sume "+Math.round(current.ordinary)+" de "+ current.course_code+ " "+current.filter );
          }

          if (!isNaN(parseInt(current.extraordinary)) && parseInt(current.filter_extra) != this.filter_id) {
            q = q + parseInt( Math.round(current.extraordinary));
            //q = q + parseInt(current.extraordinary);
            // console.log("sume "+Math.round(current.extraordinary)+" de "+ current.course_code);
          }
        }
        // console.log('this is q', q);
        return q;
      }, 0);
      // console.log('this is q', mQ);
      return Math.round((mQ * 40) / 36);
    },

    thisIsA() {
      let ff = this.data.reduce((a, current) => {
        if (parseInt(current["iorder"]) > 35 && parseInt(current["iorder"]) < 41) {
          if (parseInt(current.extra_3) > 0 && isNaN(parseInt(current.extra_4))) {
            a = a + parseInt(current.extra_3) * 4;
          }else if (parseInt(current.extra_4) >= 0 && isNaN(parseInt(current.extra_3))) {
            a = a + parseInt(current.extra_4) * 4;
          }else if (parseInt(current.extra_3) >= 0 && parseInt(current.extra_4) >= 0) {
            a = a + ((2*parseInt(current.extra_3) + parseInt(current.extra_4))/3)*4 ;
          }
        }
        return Math.round(a);
      }, 0);
      //  console.log('this is ff', ff);
      return ff;
    },

    belegt() {
      return this.data.reduce((belegt, current, index) => {
        if (parseInt(current["iorder"]) <= 35) {
          if ( Math.round(parseInt(current.ordinary)) >= 0 ) {
            belegt = belegt + 1 ;
          }
          if ( Math.round(parseInt(current.extraordinary)) >= 0 ) {
            belegt = belegt + 1 ;
          }
        }
        return belegt;
      }, 0);
    },
  
    punkte() {
       return this.data.reduce((punkte, current, index) => {
        if (parseInt(current["iorder"]) <= 35) {
          if ( Math.round(parseInt(current.ordinary))> 4  && current.filter == undefined) {
            punkte++;
          }
          if ( Math.round(parseInt(current.extraordinary)) > 4 && current.filter_extra == undefined) {
            punkte++;
          }
        }
        return punkte;
      }, 0);
    },

    gesamt() {
      return this.data.reduce((gesamt, current, index) => {
        if (parseInt(current["iorder"]) <= 35) {
          if ( Math.round(parseInt(current.ordinary)) > 0 ) {
            gesamt = gesamt + Math.round(parseInt(current.ordinary));
          }
          if ( Math.round(parseInt(current.extraordinary)) > 0 ) {
            gesamt = gesamt + Math.round(parseInt(current.extraordinary));
          }
        }
        return gesamt;
      }, 0);
    },

    allcool() {
      if (this.belegt >= 40 && (this.belegt - this.marksAvailable) == 36 && this.punkte >= 29 && this.gesamt >= 200) {
        return true;
      }
      return false;
    },

    marksAvailable() {
      // console.log('this is marksAvailable', this.data);
      let tot =  this.data.reduce(function(totalMarked, current) {
        if (parseInt(current["iorder"]) <= 35) {
          if (parseInt(current.filter) > 0) {
            totalMarked++;
            // console.log('sume ', current.course_code, totalMarked);
          }
          if (parseInt(current.filter_extra) > 0) {
            totalMarked++;
            // console.log('sume ', current.course_code, totalMarked);
          }
        }
        return totalMarked;
      }, 0);
      // console.log('this is marksAvailable', tot);
      return tot;
    }
  },

  watch: {
    thisIsQ(value) {
      this.getGrade();
    },
    thisIsA(value) {
      this.getGrade();
    }
  },

  methods: {
    async fetchData() {
      let response = await this.$api.getFirstTable(this.value);
      this.data = response.data;
      this.pinsc = response.inscriptions;

      // console.log("this is inscriptions from request: ", this.pinsc);
      // console.log("this is data from request: ", this.data);
      
      if(this.simulator) {

        let last = this.data.reduce(function(group, x) {
          let iorder = parseInt(x.iorder);        
          if ( iorder >= 36) {
            group.push(x);
          }
          return group;
        }, []);

        this.data =  [...this.simulatorDataMatches, ...last];
        // this.data = this.simulatorDataMatches;
      
        if(this.sim_slot) {
          await this.$api.getSim({studentId: this.student_id, sim_slot: this.sim_slot}).then( response => {
            // console.log('loading sim: ',response);
            if (!Array.isArray(response.data)){
              // console.log('asdasdsad');
              this.data = response.data.data;
            }
          });
        }

      }

      // console.log( "this is data after simulation replaces", this.data);
      // console.log('este es cero', Math.round(parseInt(this.data[3].extraordinary)));
    },

    async alterRecordData(record_id, field_name, value) {
      let obj = new Object();
      obj["recordId"] = record_id;
      obj[field_name] = value;
      await this.$api.postRecord(obj);
    },

    async addFilter(student, period, group_id, value) {
      let obj = new Object();
        obj["personId"] = student;
        obj["periodId"] = period;
        obj["groupId"] = group_id;
        obj["filterId"] = value;
      await this.$api.alterInscription(obj);
    },

    async getGrade() {
      // console.log(this.firstTable);
      let value = this.thisIsQ + this.thisIsA;
      if (value > 0){
        this.abiturGrade = await this.$api.getGrade({
          schemeId: this.scheme_id,
          value: this.thisIsQ + this.thisIsA
        });
      }
    },

    async saveSim(){
      let obj = new Object();
      obj["data"] = this.data;
      obj["sim_slot"] = this.sim_slot;
      obj["Q"] = this.thisIsQ;
      obj["A"] = this.thisIsA;
      obj["durchschnitt"] = this.abiturGrade["name"];
      obj["studentId"] = this.student_id;
      await this.$api.saveSim(obj);
    },

    async delSim(){
      let obj = new Object();
      obj["sim_slot"] = this.sim_slot;
      obj["studentId"] = this.student_id;
      await this.$api.deleteSim(obj);
    },

    action(grade, field_name) {
      // console.log('aqui va el action y este es el grade', grade, field_name)
      if (field_name == "filter") {
          // console.log('el valor de filter es ', grade.filter);
          return !grade.filter > 0 
      } else if (field_name == "filter_extra") {
        // console.log('el valor de filter_extra es ', grade.filter_extra);
        return !grade.filter_extra > 0;
      }
      return false;
    },

    // tck tck <3
    toggle(grade, field_name, index) {

      let marked = this.action(grade, field_name);
      // console.log('la accion es ', marked);
      let value = marked ? this.filter_id : "NULL";
      // console.log('el valor a poner es ', value , this.marksAvailable , this.max_filtered);
      if (!marked || this.marksAvailable < this.max_filtered) {
        let period =
          field_name == "filter"
            ? grade.ordinary_period
            : grade.extraordinary_period;

        // // console.log('el valor del grade es ', grade);
        // // console.log('el valor del field es ', field_name);
        // // console.log('el valor del filtro es ', value);
        if (!this.simulator) {
          this.alterRecordData(grade.id, field_name, value).then(
            this.addFilter(grade.student, period, grade.group_id, value).then(
              (this.data[index][field_name] =
                value == "NULL" ? null : value)
            )
          );
        }else{
          // console.log('ahi va!', grade);
         
          if(index){
            this.data[index][field_name] =
                  value == "NULL" ? null : value;
          }else{
            grade[field_name] =
                value == "NULL" ? null : value;
          }
        }    
      }
    },

    firstAverage(){
      // console.log('corro');
      let averages = [];
      this.data.reduce((group, current, index) => {
        let iorder = parseInt(current["iorder"]);
        if ( iorder < 36) {
          if (current.ordinary && current.extraordinary && current.course_code.includes('11')) {
            let avg1 = (parseInt(current.ordinary)+parseInt(current.extraordinary))/2;
            let avg2 = (parseInt(current.ordinary)+parseInt(current.extraordinary)+ avg1)/3;
            averages[iorder] =({
              avg1: Math.round(avg1,0),
              avg2: Math.round(avg2,0),
              order: iorder
            });
          }else if(current.course_code.includes('12') && typeof(averages[iorder]) !== 'undefined') {
            // console.log('flag2');
            // console.log(averages);
            this.data[index].ordinary = averages[iorder].avg1;
            this.data[index].extraordinary = averages[iorder].avg2;
          }
            return averages;
        }
      }, []);
    },

    saveSimulation(){
      // console.log('aqui se envia a grabar la estructura de DATA');
      this.saveSim().then(
        console.log('recorded!'));
    },

    getSimulation(){
      let obj = new Object();
      obj["data"] = this.data;
      obj["sim_slot"] = this.sim_slot;
      obj["Q"] = this.thisIsQ;
      obj["A"] = this.thisIsA;
      obj["durchschnitt"] = this.abiturGrade["name"];
      obj["studentId"] = this.student_id;
      return obj;
    },

    clearSimulation(){
      this.delSim().then( () => {
        console.log('deleted!');
        this.fetchData();
        this.getGrade();
      });
    },

    teilpunktzahlA(extra3 = null, extra4 = null){
      
      extra3 = extra3 == '' ? null: extra3;
      extra4 = extra4 == '' ? null: extra4;


      // console.log('recibo los valores', extra3, extra4); 
      if (parseInt(extra3) >= 0 && extra4 == null) {
        return Math.round((parseInt(extra3)*4));
      }else if (extra3 == null && parseInt(extra4) >= 0) {
        return Math.round((parseInt(extra4)*4));
      }else if (parseInt(extra3) >= 0 && parseInt(extra4) >= 0) {
        return Math.round(((parseInt(extra3)*2 + parseInt(extra4))/3)*4);
      }
      return ;
    }
  },
  i18n: {
		es: {
			'Abitur.fillwidthmean': 'llenar espacios con cálculo de la media',
			'Abitur.clear': 'limpiar simulación',
			'Abitur.save': 'grabar simulación',
		},
		en: {
			'Abitur.fillwidthmean': 'fill spaces with mean calculation',
			'Abitur.clear': 'clear simulation',
			'Abitur.save': 'save simulation',
		},
		de: {
			'Abitur.fillwidthmean': 'Lücken mit Mittelwertberechnung füllen',
			'Abitur.clear': 'Simulation löschen',
			'Abitur.save': 'Simulation speichern',
		},
    pt: {
			'Abitur.fillwidthmean': 'preencher espaços com cálculo médio',
			'Abitur.clear': 'limpar a simulação',
			'Abitur.save': 'guardar simulação',
		},
  }

};
</script>

<style lang="scss">
.table2_container {
  display: flex;
  justify-content: space-around;
  & > div {
    width: 50%;
  }
}

.halbja {
  text-align: center;
  margin: auto;
  & > .data {
    border: 1px solid #ccc;
    padding: 10px;
    display: inline-block;
    width: 50%;
  }
}

.abiturmatrix1,
.abiturmatrix2,
.abiturhalbja {
  border-collapse: collapse;
  & > thead {
    & > tr {
      & > td {
        text-align: center;
        font-size: 10pt;
      }
    }
  }
  &:is(.abiturhalbja) > tr { 
    & > td {
      border: none;
    }
  }
  & > tr {
    & > td {
      background-color: none;
      font-size: 10pt;
      border: 1px solid #cccccc;
      &.matrixcell {
        text-align: center;
        padding: 8px;
        background-color: #66bb6a;
        &:hover {
          border: 1px solid #cccccc;
        }
        &.clickable {
          cursor: pointer;
        }
        &.lost{
          background-color: #facc02;
        }
      }
      &.activeFilter {
        // background-color: #66bb6a;
        background-color: white;
        &:after {
          content: ")";
        }
        &:before {
          content: "(";
        }
        &:hover {
          border: 1px solid #0b570f;
        }
      }
      &.centered {
        text-align: center;
      }
      &.left {
        text-align: left;
      }
      &.big {
        font-size: 25pt;
        font-weight: bold;
      }
      &.bless {
        border: 0px;
      }
      &.error {
        color: red;
        font-size: 20px;
        font-weight: bolder;
      }
      &.enclosed {
        width: 25px;
        height: 25px;
        border: 1px solid #ccc;
        text-align: center;
      }
       &.empty {
        height: 25px;
      }
      &.input{
        text-align: center;
        input[type="text"]{
          text-align: center;
          width: fit-content;
          width: 40px;
          border: 1px dotted #cccccc;
          background-color: transparent
        }
      }
     
    }
  }
}

.abiturmatrix2 {
  & > tr {
    & > td {
      &.lost{
        color:red;
      }
      &.won{
        color:green
      }
    }
  }
}
</style>