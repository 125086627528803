<template>
  <div v-cloak>
    <!-- <extracurricular-inscriptions year-id="2" ref="inscripciones"></extracurricular-inscriptions>
    <button @click="downloadCsv">{'export to csv'|lang}</button> -->

      <!-- student_id=742  -->
    <!-- <abitur-table 
      student_id=1248
      filter_id=1
      act_id=1
      year_id=1
      scheme_id=9
      sim_slot=1
      :simulator=true>
    </abitur-table> -->

    <abitur-table 
      student_id=268
      filter_id=1
      act_id=1
      year_id=1
      scheme_id=9
      sim_slot=1
      :simulator=true>
    </abitur-table>
      
    <!-- <abitur-table 
      student_id=745
      filter_id=1
      act_id=1
      year_id=1
      :simulator=false>
    </abitur-table> -->

 <!-- student_id=945 
      filter_id=1 
      act_id=1
      year_id=4
      :scheme_id=89 
      :simulator=true 
      :st_simulation='[{"arrayIndex":0,"id":"887","iorder":36,"extra3":"","extra4":"","group_id":1795,"course_code":"DEU12","subject_name":"Deutsch \/ Alem\u00e1n","student":"945"},{"arrayIndex":0,"id":"885","iorder":37,"extra3":" ","extra4":" ","group_id":1789,"course_code":"ENG12","subject_name":"Englisch \/ Ingl\u00e9s","student":"945"},{"arrayIndex":0,"id":"895","iorder":38,"extra3":" ","extra4":" ","group_id":1820,"course_code":"BIO12","subject_name":"Biologie \/ Biolog\u00eda","student":"945"},{"arrayIndex":0,"id":"901","iorder":39,"extra3":" ","extra4":" ","group_id":1831,"course_code":"ERD12","subject_name":"Erdkunde \/ Geograf\u00eda","student":"945"},{"arrayIndex":0,"id":"906","iorder":40,"extra3":" ","extra4":" ","group_id":1839,"course_code":"PHY12","subject_name":"Physik \/ F\u00edsica","student":"945"}]'>     -->

    <!-- <abitur-table student_id=1005 filter_id=1 act_id=1 :scheme_id=89 :simulator=true>
    </abitur-table> -->
  </div>
</template>

<script>
// import UiPickerLocation from "@/modules/ui/components/UiPickerLocation/UiPickerLocation.vue";
import AbiturTable from "@/modules/v3/components/AbiturTable/AbiturTable.vue";
// import EcommerceChargeBuilder from "@/modules/ecommerce/components/ChargeBuilder/ChargeBuilder.vue";
// import ExtracurricularInscriptions from "@/modules/v3/components/V3ExtracurricularInscriptionManager/V3ExtracurricularInscriptionManager.vue";

export default {
  components: {
    // ExtracurricularInscriptions
    // EcommerceChargeBuilder,
    AbiturTable,
  },
  
  data: function () {
    return {
    }
  },

  methods: {
  //   downloadCsv: function () {

  //     var sanitizedFilters = JSON.parse(JSON.stringify(this.$refs.inscripciones.filters));
  //     if (sanitizedFilters && sanitizedFilters.slot && sanitizedFilters.slot.id) {
  //         sanitizedFilters.slot = sanitizedFilters.slot.id;
  //     }
  //     sanitizedFilters.format = 'csv';

  //     var targetUrl = this.$store.state.url + '/v3/years/' + this.$refs.inscripciones.yearId + '/academic/extracurricular/inscriptions?' + serialize(sanitizedFilters);
  //     window.open(targetUrl, '_blank');
  //   },

  //   serialize: function (obj) {
  //     var str = [];
  //     for (var p in obj)
  //         if (obj.hasOwnProperty(p) && !!obj[p]) {
  //             str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
  //         }
  //     return str.join("&");
  //   } 
  // }

  // computed: {
  //   strCharge: function () {
  //     return this.charge ? JSON.stringify(this.charge) : '';
  //   },
  //   getTotal: function() {
  //     // let innerValue = this.charge != null ? this.charge.value : 0;
  //     // return this.$cr(innerValue, this.blueprint.currency);
  //     return 0;
  //   },
  }

};
</script>
