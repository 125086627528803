export default httpClient => ({

  getFirstTable(query) {
    return httpClient.get('/v3/academic/report/official/act/abitur/getAbiturData', query);
  },

  postRecord(input) {
    return httpClient.post('/v3/academic/report/official/act/abitur/postRecord', input);
  },

  alterInscription(input) {
    return httpClient.post('/v3/academic/course/group/inscription/addfilter', input);
  },

  getGrade(query) {
    return httpClient.get('/v3/academic/scheme/getGrade', query);
  },

  saveSim(input) {
    return httpClient.post('/v3/academic/report/official/act/abitur/saveSim', input);
  },

  getSim(query) {
    return httpClient.get('/v3/academic/report/official/act/abitur/getSim', query);
  },

  deleteSim(input) {
    return httpClient.delete('/v3/academic/report/official/act/abitur/delSim', input);
  },


});